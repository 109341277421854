.Register-container {
    background-color: #000000;
    color: white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Register-form {
    background-color: #1c1c1c;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .Register-form h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .Register-form input,
  .Register-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .Register-form input[type="checkbox"] {
    width: auto;
  }
  
  .Register-form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .Register-form button:hover {
    background-color: #0056b3;
  }
  
  .Register-form .error-message {
    background-color: #ff4d4d;
    text-align: center;
    margin-top: 10px;
    color: white;
  }
  
  