/* src/pages/About.css */
.about-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    color: aliceblue;
  }
  
  .about-container h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 1.2em;
    line-height: 1.5;
  }
  