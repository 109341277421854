/* src/components/HomeImageLinks.css */

.image-link-wrapper {
   
    text-align: center;
    margin-top: 30px;
    margin-left: 38%;
    max-width: 25%;
  }
  
  .responsive-map-image {
    max-width: 100%;
    height: auto;
  }
  
  .whitetext {
    color: white;
    text-align: center;
  }