.newsfeed {
    background-color: #f9f9f930;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
   
  }
  
  .newsfeed h2 {
    text-align: center;
    color: #ffffff;
  }
  
  .news-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .news-item:last-child {
    border-bottom: none;
  }
  
  .news-date {
    font-size: 0.9em;
    color: #f3f1f1;
  }
  
  .news-content {
    font-size: 1.1em;
    color: #f6f4f4;
    margin-top: 5px;
  }
  