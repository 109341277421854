.spinner {
    width: 40px;
    height: 40px;
    margin: 0 auto 16px auto;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ff4500;
    border-radius: 50%;
    animation: spin 0.9s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .cart-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    color: white;
  }
  
  .cart-items {
    flex: 1 1 60%;
    margin-right: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    background-color: #1e1e1e;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.2);
  }
  
  .cart-item img {
    width: 120px;
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
  }
  
  .cart-details {
    flex: 1;
  }
  
  .cart-details h3 {
    margin: 0 0 8px;
  }
  
  .cart-details input {
    width: 60px;
    padding: 6px;
    margin-left: 10px;
    background: #333;
    border: 1px solid #666;
    color: white;
    border-radius: 4px;
  }
  
  .remove-button {
    background-color: #444;
    color: white;
    border: none;
    padding: 8px 14px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
  }
  
  .remove-button:hover {
    background-color: #ff4500;
  }
  
  .cart-summary {
    flex: 1 1 30%;
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 12px;
    height: fit-content;
    box-shadow: 0 0 10px rgba(255, 69, 0, 0.3);
  }
  
  .cart-summary h2 {
    margin-top: 0;
  }
  
  .checkout-button {
    margin-top: 20px;
    padding: 12px;
    width: 100%;
    background-color: #ff4500;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .checkout-button:hover {
    background-color: #e13d00;
  }
  