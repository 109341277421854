/* s/* src/components/CartIcon.css */
.cart-icon {
    cursor: pointer;
    color: white;
    position: relative;
    margin-left: 20px;
    display: flex;
    align-items: start;
  }
  
  .cart-icon:hover {
    color: #ff4500;
    transform: scale(1.1);
    transition: 0.2s ease;
  }
  
  .cart-count {
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
    min-width: 20px;
    text-align: center;
  }
  