.register-button {
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor style */
    border-radius: 4px; /* Rounded corners */
  }
  
  .register-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  