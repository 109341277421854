/* 🎨 Global Styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(135deg, #1a1a1a, #282c34);
    color: #000000;
    text-align: center;
}

/* 📦 Merchandise Form Container */
.merchandise-form {
    background: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
}

/* 📝 Form Labels */
.merchandise-form label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
    color: #ffcc00;
}

/* 📋 Form Inputs & Textareas */
.merchandise-form input,
.merchandise-form textarea,
.merchandise-form select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ffcc00;
    border-radius: 5px;
    background: #222;
    color: white;
    font-size: 16px;
}

.merchandise-form textarea {
    height: 100px;
    resize: vertical;
}

/* 🏷️ Category Dropdown */
.category-dropdown {
    background: #333;
}

/* 🔘 Checkbox Label */
.checkbox-label {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
}

/* 📷 Image Upload */
.image-label {
    margin-top: 15px;
    color: #ffcc00;
}

.image-preview {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 10px;
    border: 2px solid #ffcc00;
}

/* 🎭 Variants Section */
.variants-section {
    margin-top: 20px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.variant-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-bottom: 1px solid #444;
}

/* ➕ Add & Remove Variant Buttons */
.add-variant-btn,
.remove-variant-btn {
    background: #ff5722;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.add-variant-btn {
    background: #0dba29;
    margin-top: 10px;
}

.remove-variant-btn {
    background: #d32f2f;
}

.add-variant-btn:hover,
.remove-variant-btn:hover {
    opacity: 0.8;
}

/* 🚀 Submit Button */
.submit-btn {
    width: 100%;
    background: #ffcc00;
    color: #000;
    padding: 10px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.3s;
}

.submit-btn:hover {
    background: orange;
}

/* 📱 Responsive Adjustments */
@media (max-width: 768px) {
    .merchandise-form {
        max-width: 90%;
    }

    .variant-item {
        flex-direction: column;
    }
}
.merchandise-form {
    background: #000000;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
    color: #fff;
  }
  
  .form-input,
  .form-textarea,
  .form-select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: none;
    font-size: 16px;
  }
  
  .form-textarea {
    height: 100px;
  }
  
  .form-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }
  
  .form-file {
    margin: 10px 0;
  }
  
  .form-button {
    background: #ff4500;
    color: white;
    padding: 10px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-button:hover {
    background: #ff5722;
  }
  
  .error-message {
    background: #ff3b3b;
    color: white;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:flex-start;
    padding: 5px 10px; /* Reduce padding */
    max-width: 100%;
    
    
}

.App-header {
    background-color: #282c34;
    min-height: auto !important;  /* ✅ Allow it to adjust based on content */
    height: 80px;  /* ✅ Set a smaller fixed height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
    width: 100%;
    padding: 10px 0; /* ✅ Reduce padding */
    overflow: hidden; /* ✅ Prevent it from stretching */
}

.merch-area {
    border: 2px solid #ccc; /* light gray border */
    border-radius: 8px;      /* optional: rounded corners */
    padding: 16px;           /* optional: space inside the border */
    margin: 16px 0;          /* optional: spacing around the element */
  }

  .post-area {
    border: 2px solid #ccc; /* light gray border */
    border-radius: 8px;      /* optional: rounded corners */
    padding: 16px;           /* optional: space inside the border */
    margin: 16px 0;          /* optional: spacing around the element */
  }
  

