/* src/components/ArtistPostForm.css */
.post-form-container {
    background: #fff;
    border-radius: 10px;
    padding: 16px;
    margin: 20px auto;
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  }
  
  .post-form textarea {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    resize: vertical;
    font-size: 16px;
    min-height: 100px;
  }
  
  .form-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .upload-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
  }
  
  .upload-label input {
    display: none;
  }
  
  .checkbox {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    color:black;
  }
  
  .post-form button {
    background-color: #0d6efd;
    color: white;
    border: none;
    padding: 10px 18px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .post-form button:hover {
    background-color: #084fc1;
  }
  
  .post-error {
    color: red;
    margin-top: 10px;
  }
  