/* Ensures the dashboard takes up the full height of the browser */
.user-dashboard {
  background-color: black;
  color: white;
  min-height: 100vh; /* Ensures it covers at least the full viewport height */
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

/* Styles for the create button */
.create-button {
  background-color: #ff66c2;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

/* Styles for the form */
.create-form {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333; /* Dark background for the form */
}

/* Input and textarea in the form */
.create-form input,
.create-form textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #222; /* Darker input fields */
  color: rgb(244, 241, 241);
  border: 1px solid #555;
  border-radius: 5px;
}

/* Form buttons */
.create-form button {
  margin-right: 10px;
  padding: 10px
}

.artist-page-button {
  background-color: #6673ff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
}