.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

#thename {
  display: flex;
  align-items: center; /* Center items vertically */
}

h2 {
  margin-right: 10px;
  font-family: Arial;
  font-size: 30pt;
  font-weight: 100;
}

.login-button {
  margin-left: auto;
  background-color: #007bff; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Cursor style */
  border-radius: 4px; /* Rounded corners */
}

.login-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
/* unvisited link */
a:link {
  color: red;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: hotpink;
}

/* selected link */
a:active {
  color: blue;
}