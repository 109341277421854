.artist-post-list {
    margin-top: 30px;
  }
  
  .post-card {
    background-color: #fff;
    color: #000;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
  }
  
  .post-date {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 10px;
  }
  
  .post-content {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .post-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .post-images img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .url-preview {
    display: flex;
    margin-top: 10px;
    gap: 12px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .preview-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .preview-info {
    flex: 1;
  }
  