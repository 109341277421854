/* Set the background to black and text to white for a dark theme */
.artist-dashboard {
    background-color: black;
    color: white;
    min-height: 100vh; /* Ensure the background covers the full viewport height */
    padding: 20px; /* Add some padding for better layout */
    font-family: Arial, sans-serif; /* Use a clean, modern font */
  }
  
  /* Style the main title of the artist dashboard */
  .artist-dashboard h2 {
    color: #ff66c2; /* Use the light pink color for titles */
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Style the description text */
  .artist-dashboard p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  /* Add a subtle border and padding to sections */
  .artist-section {
    border: 1px solid #333;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px; /* Rounded corners for a modern look */
    background-color: #1a1a1a; /* Slightly lighter black for contrast */
  }
  
  /* Style buttons with the light pink color */
  .artist-dashboard button {
    background-color: #ff66c2;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .artist-dashboard button:hover {
    background-color: #ff3399; /* Darken the pink on hover */
  }
  
  /* Style links */
  .artist-dashboard a {
    color: #ff66c2;
    text-decoration: none;
    font-weight: bold;
  }
  
  .artist-dashboard a:hover {
    text-decoration: underline;
  }
  
  /* Add responsive design */
  @media (max-width: 768px) {
    .artist-dashboard {
      padding: 10px;
    }
  
    .artist-dashboard h2 {
      font-size: 1.5rem;
    }
  
    .artist-dashboard p {
      font-size: 1rem;
    }
  }
  