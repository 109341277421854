/* src/pages/SignUp.css */
.user-type-buttons, .artist-type-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.user-type-button, .artist-type-button {
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, border-color 0.3s;
}

.user-type-button.selected, .artist-type-button.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.user-type-button.selected:hover, .artist-type-button.selected:hover {
  background-color: #ff66c2; /* Lighter blue on hover */
}

.user-type-button:not(.selected):hover, .artist-type-button:not(.selected):hover {
  background-color: #e0e0e0;
}


  #space {
    margin-top: 30;
  }

  .submit-button {
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 20px;
    cursor: pointer;
    border: 2px solid #007bff;
    border-radius: 5px;
    
    
  
  }